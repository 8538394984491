<template>
  <div>
    <div class="button_top">
      <div class="faultBtn">
        <div :class="{ none: true, active: examineState === '00' }" @click="btnClick('00')">
          待审核({{ waitNum || 0 }})
        </div>
        <div :class="{ none: true, active: examineState === '01' }" @click="btnClick('01')">
          已审单({{ examNum || 0 }})
        </div>
      </div>
      <!-- <el-checkbox v-model="checked" @change="handleCurrentChange()">过滤其他人在审单订单</el-checkbox> -->
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-if="tableShow"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column
        label="距离推送报价时间"
        slot="pushQuoteTime"
        align="center"
      >
        <template v-if="row.pushQuoteTime > 0" slot-scope="{ row }">
          <el-statistic
            format="HH:mm:ss"
            :value="Date.now() + row.pushQuoteTime * 1000"
            time-indices
            @finish="handleCurrentChange(page.pageNum)"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="examineState == '00' ? '当前审单人' : '审单人'"
        slot="examineAdminName"
        width="150px"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="row.examineAdminAcc">
            {{ row.examineAdminName }}-{{ row.examineAdminAcc }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="是否修改报告" slot="isUpdateReport" align="center">
        <template slot-scope="{ row }">
          <span>
            {{ row.isUpdateReport ? '是' : '否' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="120" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" :type="examineState == '00' ? 'success' : 'primary'" @click="$refs['quoteAuditDia'].open(row, examineState)">
            {{ examineState == '00' ? '进入审单' : '审单详情' }}
          </el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <QuoteAuditDia ref="quoteAuditDia" @confirm="handleCurrentChange()" />
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
import QuoteAuditDia from "./dialog/QuoteAuditDia.vue";

const publicColumns = [
  { label: "订单号", prop: "orderNo" },
  { label: "机型", prop: "phoneName" },
  { label: "串号", prop: "imei" },
  { label: "下单时间", prop: "orderTime" },
  { label: "预估价(元)", prop: "prePrice" },
]
export default {
  components: { QuoteAuditDia },
  data() {
    return {
      tableColumns: [],
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      examineState: '00',
      loading: false,
      waitNum: 0,
      examNum: 0,
      tableShow: true
    };
  },
  created() {
    this.initColums()
  },
  mounted() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.handleCurrentChange();
    },
    examineState() {
      this.initColums()
    }
  },
  methods: {
    // 切换页
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        companyId: this.SeachParams.companyId,
        imei: this.SeachParams.imei,
        orderNo: this.SeachParams.orderNo,
        phoneNameShrink: this.SeachParams.phoneNameShrink,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        examineState: this.examineState,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api
        .examineOrderList(baseRequest)
        .then((res) => {
          this.seachDataList = res.data.page.records;
          this.page.total = res.data.page.total;
          this.page.pageNum = res.data.page.current;
          this.waitNum = res.data.waitNum || 0;
          this.examNum = res.data.examNum || 0;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    btnClick(state) {
      this.examineState = state
      this.handleCurrentChange()
    },
    initColums() {
      const { examineState } = this
      this.tableShow = false
      switch(examineState) {
        case '00':
          this.tableColumns = publicColumns.concat([
            { label: "当前取价", prop: "thenMaxQuotePrice" },
            { label: "取价商家", prop: "thenMaxQuoteMerchantName" },
            { label: "当前最高价", prop: "maxQuotePrice" },
            { slotName: "pushQuoteTime" },
            { slotName: "examineAdminName" },
            { slotName: "operation" }
          ])
          break;
        case '01':
          this.tableColumns = publicColumns.concat([
            { label: "审单金额", prop: "examinePrice" },
            { slotName: "examineAdminName" },
            { label: "审单时间", prop: "examineTime" },
            { label: "当前推送报价", prop: "currentPushQuote" },
            // { label: "是否修改报告", prop: "isUpdateReport" },
            { slotName: "isUpdateReport" },
            { slotName: "operation" }
          ])
          break;
      }
      this.seachDataList = []
      this.$nextTick(() => {
        this.tableShow = true
      })
    },
    timeManage(time) {
      const t = Math.abs(time);
      return moment(t).format("H:mm:ss");
    },
  },
};
</script>

<style lang="scss" scoped>
.button_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .faultBtn {
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .none {
      text-align: center;
      color: #333;
      padding: 0 15px;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }

    .active {
      color: white;
      background: #0981ff;
    }
  }
}

/deep/.el-statistic .con {
  color: #FF687B;
}
/deep/.el-statistic .con .number{
  font-size: inherit;
}
</style>
