<template>
  <el-dialog
    v-loading="loading"
    title="报价审单"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="1400px"
    top="0"
  >
    <el-row style="margin-bottom: 10px">
      <el-col :span="8">{{info.phoneName}}（{{info.phoneStorage}}）</el-col>
      <el-col :span="8">预估价：<el-link type="danger" :underline="false">¥{{info.prePrice}}</el-link></el-col>
      <el-col :span="8">当前推送报价：{{row.thenMaxQuoteMerchantName}}（成交价<el-link type="danger" :underline="false">¥{{row.thenMaxQuotePrice}}</el-link>）</el-col>
    </el-row>
    <div class="title">实拍照片</div>
    <div class="img-list">
      <div v-for="(item, index) in info.images" :key="index" class="img-item">
        <el-image class="img" :src="item.img" fit="cover" :preview-src-list="info.images.map(img => img.img)"></el-image>
        <div class="des">{{ item.sampleName }}</div>
      </div>
    </div>
    <!-- <el-divider style="margin: 10px 0"></el-divider> -->
    <hr class="line">
    <el-row :gutter="25" style="margin-bottom: 10px">
      <el-col :span="6">
        <div class="title">旧机串号查询</div>
        <div class="report-box">
          <div v-for="item in categoryList" :key="item.id">
            <div v-if="categoryInfo[item.id]"><el-link type="primary" :underline="false" >{{item.name}}</el-link></div>
            <div v-else><el-link type="primary" @click="reportSearch(item)">{{item.name}} ></el-link></div>
            <div v-if="categoryInfo[item.id]">
              <div v-for="(c, i) in categoryInfo[item.id]" :key="i">{{c.key}}：{{c.value}}</div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="title">修改验机报告</div>
        <el-table :data="info.reports" border size="mini" max-height="500px">
          <el-table-column label="序号" type="index" align="center" />
          <el-table-column label="质检项" align="center" prop="labelName" width="100px" />
          <el-table-column label="原报告" width="140px">
            <template slot-scope="{ row }">
              <el-tag v-for="item in row.oriReport" :key="item.id" :type="item.isSelected ? 'success' : 'info'" size="mini" class="origin-tag">{{item.labelName}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="修改报告" width="auto">
            <template slot-scope="{ row, $index }">
              <el-tag
                v-for="(item, index) in row.modifyReport"
                :key="item.id"
                :type="item.isSelected ? 'danger' : 'info'"
                size="mini"
                style="margin-right: 6px"
                @click="selectRoportItem($index, index)"
              >
                {{item.labelName}}
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div class="title">推送报价备注</div>
    <el-input v-model="note" :disabled="isDetail" type="textarea" show-word-limit rows="2" maxlength="200" placeholder="请输入备注信息" />
    <el-row v-if="isDetail" style="margin-top:10px">
      <el-col :span="8">审单人：{{ info.examineAdminName }}-{{ info.examineAdminAcc }}</el-col>
      <el-col :span="8">审单时间：{{info.examineTime}}</el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="!isDetail" size="small" @click="visible = false">取消</el-button>
      <el-button v-if="!isDetail" size="small" type="primary" @click="confirm">确定提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
import { confirm } from '@/components/common/messagebox'
export default {
  name: "QuoteAuditDia",
  data() {
    return {
      visible: false,
      loading: false,

      row: {},
      info: {},
      note: '',
      state: '',
      categoryList: [],
      categoryInfo: {}
    };
  },
  computed: {
    isDetail() {
      if (this.state != '00') return true
      return false
    }
  },
  methods: {
    open(row, state) {
      this.row = row
      this.state = state
      this.note = ''
      this.categoryInfo = {}
      this.getInfo()
      this.getCategory()
      this.visible = true
    },
    getInfo() {
      _api.examineOrderInfo({ orderNo: this.row.orderNo }).then(res => {
        console.log(res)
        this.info = res.data
        if (this.state != '00') this.note = res.data.examineNote
      })
    },
    getCategory() {
      _api.getDetectionCategory({ brandNo: this.row.brandNo }).then(res => {
        console.log(res)
        this.categoryList = res.data
      })
    },
    reportSearch(item) {
      confirm({
        title: '查询提示',
        message: `本次查询平台将支出<span style="color:red">${item.oncePrice}</span>元用以查询，是否确认查询？`,
        confirm: () => {
          _api.doDetection({
            detectionCategoryId: item.id,
            imei: this.info.imei,
            orderNo: this.info.orderNo
          }).then((res) => {
            const { detectionCategoryId, detailModels } = res.data
            this.$set(this.categoryInfo, detectionCategoryId, detailModels)
          })
        }
      })
    },
    selectRoportItem(rowIndex, itemIndex) {
      // console.log(rowIndex, itemIndex)
      if (this.isDetail) return
      const items = this.info.reports[rowIndex].modifyReport
      const item = items[itemIndex]
      if (item.isSelected) {
        item.isSelected = false
      } else {
        items.forEach(i => i.isSelected = false)
        item.isSelected = true
      }
    },
    confirm() {
      const list = this.info.reports.reduce((prev, item) => {
        const selectItem = item.modifyReport?.find(r => r.isSelected)
        if (selectItem) {
          prev.push({
            id: selectItem.id,
            name: selectItem.labelName,
            parentId: item.id
          })
        }
        return prev
      }, [])
      _api.examineOrderModify({
        list,
        note: this.note,
        orderNo: this.info.orderNo
      }).then(() => {
        this.$message.success('提交成功')
        this.visible = false
        this.$emit('confirm')
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
}

.img-list{
  background: #f5f5f5;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding: 0 10px;
  .img-item {
    flex-shrink: 0;
    margin-right: 10px;
    position: relative;s
    &:last-child{
      margin-right: 0;
    }
    .img {
      width: 70px;
      height: 120px;
    }
    .des{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px;
      background: rgba($color: #FFF, $alpha: 0.8);
      color: red;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.line {
  margin-top: 15px;
  margin-bottom: 10px;
}
.report-box{
  background: #f5f5f5;
  width: 100%;
  padding: 10px;
  overflow-y: auto;
  // max-height: 350px;
  max-height: 500px;
}
.origin-tag{
  &::v-deep.el-tag{
    height: auto;
    line-height: 1;
    white-space: normal;
    padding: 3px 5px;
  }
}
</style>